<template>
  <div class="h-full">
    <div class="drawer-header d-flex align-center mb-0">
      <span class="font-weight-semibold text-base text--primary">{{ option === 1 ? t('tooltip.create') : option === 3 ? t('tooltip.edit') : '' }} {{ isContact ? t('Contact') : t('Category') }}</span>
      <v-spacer></v-spacer>
      <v-btn
        icon
        small
        @click="$emit('update:is-open', false)"
      >
        <v-icon size="22">
          {{ icons.mdiClose }}
        </v-icon>
      </v-btn>
    </div>

    <v-divider />

    <perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="ps-calendar-event-handler"
    >
      <contact-form
        :is-open="isOpen"
        :option="option"
        :is-contact="isContact"
        :contact-id="contactId"
        :category-id="categoryId"
        @refresh="$emit('refresh')"
        @open-drawer="(val) => $emit('open-drawer', val)"
        @update-option="(val) => $emit('update-option', val)"
      />
    </perfect-scrollbar>
  </div>
</template>

<script>
/* eslint-disable object-curly-newline */
import { mdiClose } from '@mdi/js'
import { useUtils } from '@core/libs/i18n'

// import CustomCheckboxes from '@core/components/custom-checkboxes/CustomCheckboxes.vue'
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'
import ContactForm from './ContactForm.vue'

export default {
  name: 'ContactDrawer',
  components: {
    PerfectScrollbar,
    ContactForm,
  },
  model: {
    prop: 'isOpen',
    event: 'update:is-open',
  },
  props: {
    isOpen: {
      type: Boolean,
      required: true,
      default: false,
    },
    option: {
      type: Number,
      required: true,
      default: 2,
    },
    isContact: {
      type: Boolean,
      required: true,
      default: true,
    },
    contactId: {
      type: Number,
      required: false,
      default: 0,
    },
    categoryId: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  setup() {
    const { t } = useUtils()

    // ————————————————————————————————————
    //* ——— Perfect Scrollbar
    // ————————————————————————————————————
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
      wheelSpeed: 0.7,
    }

    return {
      perfectScrollbarSettings,

      // i18n
      t,

      // icons
      icons: {
        mdiClose,
      },
    }
  },
}
</script>

  <style scoped lang="scss">
  @import '~vuetify/src/styles/styles.sass';

  @include theme(v-subheader) using ($material) {
    white-space: nowrap;
    position: relative;
    height: 20px;

    .v-icon {
      color: map-deep-get($material, 'dividers');
    }
    .title-wrapper {
      &:not(.no-style) {
        width: 100%;
        position: absolute;
        left: 0;
        border-bottom: 1px solid map-deep-get($material, 'dividers');
        line-height: 0.1em;
      }
    }

    .title-wrapper span {
      background: map-deep-get($material, 'background');
      padding: 0 10px;

      font-size: 1rem !important;
      font-weight: 400;
      letter-spacing: 0.03125em !important;

      @include ltr() {
        margin-left: 16px;
      }
      @include rtl() {
        margin-right: 16px;
      }
    }
  }

  .theme--light.v-btn--active, .theme--dark.v-btn--active {
    background-color: #ff3232 !important;
  }
  </style>

  <style lang="scss">
  .custom-checkbox {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 0.8rem;
    border: 1px solid rgba(0, 0, 0, 0.14);

    .v-checkbox {
      margin-block-start: -0.375rem;
    }

    .cr-title {
      font-weight: 500;
    }
  }
  </style>
