var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"contac-list"}},[_c('v-sheet',{attrs:{"color":"transparent"}},[_c('v-slide-group',{attrs:{"mandatory":"","show-arrows":_vm.$vuetify.breakpoint.smAndDown,"mobile-breakpoint":_vm.$vuetify.breakpoint.thresholds.md + 200},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.tabs),function(tab){return _c('v-slide-item',{key:tab.icon,scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-btn',{staticClass:"mx-2 px-3",attrs:{"input-value":active,"active-class":"secondary white--text","depressed":"","text":""},on:{"click":toggle}},[_c('v-icon',{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(tab.icon)+" ")]),_c('span',[_vm._v(_vm._s(_vm.$t(tab.title)))])],1)]}}],null,true)})}),1)],1),_c('v-tabs-items',{staticClass:"mt-5 px-2 overflow-visible",model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',[_c('v-card',[_c('v-row',{staticClass:"px-2 ma-0 text-center"},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"3"}},[_c('v-text-field',{attrs:{"placeholder":_vm.t('search'),"outlined":"","hide-details":"","dense":""},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}})],1),_c('v-spacer'),(_vm.hasPermission(116))?_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('v-btn',{attrs:{"color":"secondary","block":""},on:{"click":function($event){_vm.isViewDrawerActive = true}}},[_vm._v(" "+_vm._s(_vm.t('tooltip.new'))+" "+_vm._s(_vm.t('contacts.contact'))+" ")])],1):_vm._e()],1),_c('v-data-table',{attrs:{"headers":_vm.computedTableColumns,"items":_vm.listTable,"item-key":"id","options":_vm.options,"loading":_vm.loading,"loading-text":_vm.t('table.loading_text'),"no-data-text":_vm.t('table.no_data'),"header-props":{sortByText: _vm.t('table.sort_by')},"footer-props":{itemsPerPageText: _vm.t('table.item_page')}},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.fullname",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-avatar',{class:item.avatar ? '' : 'v-avatar-light-bg primary--text',attrs:{"color":"primary","size":"32"}},[(item.avatar)?_c('v-img',{attrs:{"src":item.avatar}}):_c('span',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(_vm.avatarText(item.fullname)))])],1),_c('div',{staticClass:"d-flex flex-column ms-3"},[_c('span',[_vm._v(" "+_vm._s(item.user_app_id ? item.user_fullname : item.fullname)+" ")]),(item.user_app_id)?_c('span',{staticClass:"text-xs"},[_vm._v(" "+_vm._s(item.fullname)+" ")]):_vm._e()])],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.hasPermission(117))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"me-2",attrs:{"size":"20","color":"success"},on:{"click":function($event){return _vm.onEdit(item)}}},'v-icon',attrs,false),Object.assign({}, tooltip)),[_vm._v(" "+_vm._s(_vm.icons.mdiPencilOutline)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('tooltip.edit')))])]):_vm._e(),(_vm.hasPermission(118))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"me-2",attrs:{"size":"20","color":"error"},on:{"click":function($event){return _vm.onDelete(item.id)}}},'v-icon',attrs,false),Object.assign({}, tooltip)),[_vm._v(" "+_vm._s(_vm.icons.mdiDeleteOutline)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('tooltip.delete')))])]):_vm._e()]}}],null,true)})],1)],1),_c('v-tab-item',[_c('v-card',[_c('v-row',{staticClass:"px-2 ma-0 text-center"},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"3"}},[_c('v-text-field',{attrs:{"placeholder":_vm.t('search'),"outlined":"","hide-details":"","dense":""},model:{value:(_vm.searchQueryCat),callback:function ($$v) {_vm.searchQueryCat=$$v},expression:"searchQueryCat"}})],1),_c('v-spacer'),(_vm.hasPermission(125))?_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('v-btn',{attrs:{"color":"secondary","block":""},on:{"click":function($event){_vm.isViewDrawerActive = true}}},[_vm._v(" "+_vm._s(_vm.t('tooltip.new'))+" "+_vm._s(_vm.t('Category'))+" ")])],1):_vm._e()],1),_c('v-data-table',{attrs:{"headers":_vm.computedTableColumnsCategories,"items":_vm.listTableCategories,"item-key":"id","options":_vm.optionsCategories,"loading":_vm.loading,"loading-text":_vm.t('table.loading_text'),"no-data-text":_vm.t('table.no_data'),"header-props":{sortByText: _vm.t('table.sort_by')},"footer-props":{itemsPerPageText: _vm.t('table.item_page')}},on:{"update:options":function($event){_vm.optionsCategories=$event}},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"v-chip-light-bg font-weight-semibold text-capitalize",class:((_vm.resolveStatusVariant(item.status)) + "--text"),attrs:{"small":"","color":_vm.resolveStatusVariant(item.status)}},[_vm._v(" "+_vm._s(_vm.resolveStatusText(item.status))+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.hasPermission(126))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"me-2",attrs:{"size":"20","color":"success"},on:{"click":function($event){return _vm.onEdit(item)}}},'v-icon',attrs,false),Object.assign({}, tooltip)),[_vm._v(" "+_vm._s(_vm.icons.mdiPencilOutline)+" ")])]}}],null,true)},[_c('span',[_vm._v("Edit")])]):_vm._e(),(_vm.hasPermission(127))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"me-2",attrs:{"size":"20","color":"error"},on:{"click":function($event){return _vm.onDelete(item.id)}}},'v-icon',attrs,false),Object.assign({}, tooltip)),[_vm._v(" "+_vm._s(_vm.icons.mdiDeleteOutline)+" ")])]}}],null,true)},[_c('span',[_vm._v("Delete")])]):_vm._e()]}}],null,true)})],1)],1)],1),_c('v-navigation-drawer',{attrs:{"right":!_vm.$vuetify.rtl,"touchless":"","app":"","temporary":"","width":_vm.$vuetify.breakpoint.mdAndUp ? '60%' : '100%'},model:{value:(_vm.isViewDrawerActive),callback:function ($$v) {_vm.isViewDrawerActive=$$v},expression:"isViewDrawerActive"}},[_c('contact-drawer',{attrs:{"is-open":_vm.isViewDrawerActive,"option":_vm.option,"is-contact":_vm.tab === 0,"contact-id":_vm.contactId,"category-id":_vm.categoryId},on:{"refresh":_vm.onRefresh,"update-option":function (val) { return _vm.option = val; },"open-drawer":function (val) { return _vm.isViewDrawerActive = val; },"update:input-value":function (val) { return _vm.isViewDrawerActive = val; }},model:{value:(_vm.isViewDrawerActive),callback:function ($$v) {_vm.isViewDrawerActive=$$v},expression:"isViewDrawerActive"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }